/**
 * Filtering form helper
 *
 * @since   1.0.0
 * @package uma
 */

(function($) {

	/*
	 *  new_map
	 *
	 *  This function will render a Google Map onto the selected jQuery element
	 *
	 *  @since  1.0.0
	 *
	 *  @param  $el (jQuery element)
	 *  @return n/a
	 */

	function new_map($el) {

		// var
		var $markers = $el.find('.marker');


		// vars
		var args = {
			zoom: 16,
			center: new google.maps.LatLng(0, 0),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true
		};


		// create map
		var map = new google.maps.Map($el[0], args);

		// create info window
		var infowindow = new google.maps.InfoWindow({
			maxWidth: 300
		});


		// add a markers reference
		map.markers = [];


		// add markers
		$markers.each(function() {

			add_marker($(this), map, infowindow);

		});


		// center map
		center_map(map);


		// return
		return map;

	}

	/*
	 *  add_marker
	 *
	 *  This function will add a marker to the selected Google Map
	 *
	 *  @since  1.0.0
	 *
	 *  @param  $marker (jQuery element)
	 *  @param  map (Google Map object)
	 *  @return n/a
	 */

	function add_marker($marker, map, infowindow) {

		// var
		var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

		// Marker svg
		var icon = {
			path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
			fillColor: '#156de5',
			fillOpacity: 1,
			anchor: new google.maps.Point(250,540),
		    strokeWeight: 0,
		    scale: 0.05
		};

		// Marker svg
		var icon2 = {
			path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
			fillColor: '#15CB87',
			fillOpacity: 1,
			anchor: new google.maps.Point(250,540),
		    strokeWeight: 0,
		    scale: 0.06
		};

		// create marker
		var marker = new google.maps.Marker({
			position: latlng,
			map: map,
			animation: google.maps.Animation.DROP,
			icon: icon
		});

		marker.addListener('mouseover', function() {
			if (marker.getAnimation() == null) {
				marker.setAnimation(google.maps.Animation.BOUNCE);
				marker.setIcon(icon2);
			} else {
				marker.setAnimation(null);
				marker.setIcon(icon);
			}
		});
		marker.addListener('mouseout', function() {
			if (marker.getAnimation() !== null) {
				marker.setAnimation(null);
				marker.setIcon(icon);
			} else {
				marker.setIcon(icon2);
			}
		});

		// add to array
		map.markers.push(marker);

		// if marker contains HTML, add it to an infoWindow
		if ($marker.html()) {
			

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {

				infowindow.setContent($marker.html());
				infowindow.open(map, marker);

			});

			// close any open info window when map is clicked
			google.maps.event.addListener(map, 'click', function(event) {

			    infowindow.close();

			});

		}

	}

	/*
	 *  center_map
	 *
	 *  This function will center the map, showing all markers attached to this map
	 *
	 *  @since  1.0.0
	 *
	 *  @param  map (Google Map object)
	 *  @return n/a
	 */

	function center_map(map) {

		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each(map.markers, function(i, marker) {

			var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

			bounds.extend(latlng);

		});

		// only 1 marker?
		if (map.markers.length == 1) {
			// set center of map
			map.setCenter(bounds.getCenter());
			map.setZoom(16);
		} else {
			// fit to bounds
			map.setCenter({lat:42, lng:-100});
			// map.setCenter(bounds.getCenter());
			map.setZoom(4.5); // Change the zoom value as required
			// map.fitBounds( bounds ); // This is the default setting which I have uncommented to stop the World Map being repeated

		}

	}

	/*
	 *  document ready
	 *
	 *  This function will render each map when the document is ready (page has loaded)
	 *
	 *  @since  1.0.0
	 *
	 *  @param  n/a
	 *  @return n/a
	 */
	// global var
	var map = null;

	jQuery(document).ready(function($) {

		$('.projects-map').each(function() {

			// create map
			map = new_map($(this));

			// fade out map loader overlay
			map.addListener('tilesloaded', function () { 
				$('.projects-map-container .overlay').fadeOut();
			});

		});


	});

})(jQuery);